import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { QueryBuilderService } from '@app/services/query-builder.service'
import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'
import { Attachment } from '../modules/shared/models/attachment.model'

@Injectable({
    providedIn: 'root'
})
export class AttachmentService {
    private readonly calibrationUrl = `${environment.baseUrl}/api/${AppSettings.apiVersion}/Calibrations`
    private readonly equipmentUrl = `${environment.baseUrl}/api/${AppSettings.apiVersion}/Equipments`

    constructor(private queryBuilderService: QueryBuilderService) { }

    public getAttachment(attachmentBlobUrl: string): void {
        window.open(attachmentBlobUrl)
    }

    public deleteCalibrationAttachment(calibrationId: string, attachmentIds: string[]): Observable<boolean> {
        return this.queryBuilderService
            .deleteAttachments<boolean>(this.calibrationAttachmentUrlBuilder(calibrationId), attachmentIds)
            .pipe(map(response => {
                if (response.status === 204) {
                    return true
                }
                return false
            }))
    }

    public getCalibrationAttachments(calibrationId: string): Observable<Attachment[]> {
        return this.queryBuilderService
            .get<Attachment[]>(this.calibrationAttachmentUrlBuilder(calibrationId))
            .pipe(map(response => response.body))
    }

    public uploadCalibrationAttachment(calibrationId: string, files: File[]): Observable<Attachment> {
        const formData = new FormData()
        files.forEach(file => {
            formData.append('file', file)
        })

        return this.queryBuilderService
            .postUpload<Attachment>(this.calibrationAttachmentUrlBuilder(calibrationId), formData)
            .pipe(map(response => response.body))
    }

    public deleteEquipmentSettingAttachment(equipmentId: string, attachmentIds: string[]): Observable<boolean> {
        return this.queryBuilderService
            .deleteAttachments<boolean>(this.equipmentSettingAttachmentUrlBuilder(equipmentId), attachmentIds)
            .pipe(map(response => {
                if (response.status === 204) {
                    return true
                }
                return false
            }))
    }

    public getEquipmentSettingAttachments(equipmentId: string): Observable<Attachment[]> {
        return this.queryBuilderService
            .get<Attachment[]>(this.equipmentSettingAttachmentUrlBuilder(equipmentId))
            .pipe(map(response => response.body))
    }

    public uploadEquipmentSettingAttachment(equipmentId: string, files: File[]): Observable<Attachment> {
        const formData = new FormData()
        files.forEach(file => {
            formData.append('file', file)
        })

        return this.queryBuilderService
            .postUpload<Attachment>(this.equipmentSettingAttachmentUrlBuilder(equipmentId), formData)
            .pipe(map(response => response.body))
    }

    private calibrationAttachmentUrlBuilder(calibrationId: string): string {
        return `${this.calibrationUrl}(${calibrationId})/Attachments`
    }

    private equipmentSettingAttachmentUrlBuilder(equipmentId: string): string {
        return `${this.equipmentUrl}(${equipmentId})/TestIntervalSetting/Attachments`
    }
}
